// @ts-ignore
import Trakt from "nodeless-trakt";

const options = {
  client_id: "f5b19ea0b39fdefe33d48922e03e46df352bd479a9cb8d1619e17cfad855a3c3",
  client_secret:
    "27fbe27013d7bc46bd6ee956093d51605e42ec917c42321651558b2688593a0a",
  redirect_uri: `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://ionic-dionysus.acardosi.dev"
  }/callback/trakt`,
  api_url: null, // defaults to 'https://api.trakt.tv'
  useragent: null, // defaults to 'trakt.tv/<version>'
  pagination: true, // defaults to false, global pagination (see below)
};
const trakt = new Trakt(options);

export default trakt;

import cheerio from "cheerio";
import pad from "../pad";
import { isMobile } from "react-device-detect";
import trakt from "./trakt";

export async function getLinks(term: string) {
  const res = await fetch(
    `https://corsssssss.herokuapp.com/https://filepursuit.com/pursuit?q=${encodeURIComponent(
      term
    )}`
  );

  const text = await res.text();
  const $ = cheerio.load(text);
  const linkElems = $(".badge-wrap > a");

  let links: string[] = [];

  linkElems.each((i, elem) => {
    let link = elem.attribs.onclick;

    link = link.replace(
      "if (!window.__cfRLUnblockHandlers) return false; copyToClipboard('",
      ""
    );
    link = link.replace("')", "");
    links.push(link);
  });

  return links;
}

export async function getLinksByShow(
  name: string,
  episode: number,
  season: number
) {
  return await getLinks(`${name} s${pad(season)}e${pad(episode)}`);
}

export default async function startPlaying(
  name: string,
  episode: number,
  season: number,
  episodeId: number
) {
  const links = await getLinksByShow(name, episode, season);
  playLink(links[0], episodeId);
}

export async function playLink(link: string, episodeId: number) {
  await trakt.sync.history.add({
    episodes: [
      {
        ids: {
          trakt: episodeId,
        },
      },
    ],
  });
  if (isMobile) {
    window.location.href = `vlc://${link}`;
  } else {
    alert(link);
    // window.open(link, "watch");
  }
}
